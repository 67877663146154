export const ADMIN_ROLE_LIST = [
  {
    roleID: 256,
    roleName: 'CLIA Officer',
  },
  {
    roleID: 512,
    roleName: 'CLIA Manager',
  },
  {
    roleID: 128,
    roleName: 'System Administrator',
  },
];

export const USER_ROLE_LIST = [
  {
    roleID: 2,
    roleName: 'User',
  },
  {
    roleID: 256,
    roleName: 'Officer',
  },
  {
    roleID: 512,
    roleName: 'Manager',
  },
  {
    roleID: 128,
    roleName: 'Admin',
  },
];

export const USER_ACCOUNT_STATUS = [
  {
    statusID: 101,
    statusName: 'Active',
  },
  {
    statusID: 104,
    statusName: 'Disabled',
  },
];

export const SUBSCRIPTION_STATUS = [
  {
    statusID: 301,
    statusName: 'New',
  }, 
  {
    statusID: 310,
    statusName: 'Active',
  },
  {
    statusID: 314,
    statusName: 'Expired',
  },
];

export const PUBLICATION_STATUS = [
  {
    statusID: 201,
    statusName: 'Draft',
  }, 
  {
    statusID: 203,
    statusName: 'Sent for approval',
  },
  {
    statusID: 210,
    statusName: 'Approved',
  },
  {
    statusID: 214,
    statusName: 'Hide',
  },
];

export const SURVEY_STATUS = [
  {
    statusID: 902,
    statusName: 'Pending Completion',
  }, 
  {
    statusID: 920,
    statusName: 'Confirmed',
  },
  {
    statusID: 924,
    statusName: 'Rejected',
  },
];


export const PUBLICATION_TYPE = [
  {
    publicationTypeCode: 'BS',
    publicationTypeName: 'Benefits Information'
  },
  {
    publicationTypeCode: 'LD',
    publicationTypeName: 'Learning & Development'
  },
  {
    publicationTypeCode: 'SS',
    publicationTypeName: 'Salary Information'
  },
  {
    publicationTypeCode: 'WEO',
    publicationTypeName: 'Wage Adjustment & Variable Bonus'
  },
  {
    publicationTypeCode: 'OT',
    publicationTypeName: 'Others'
  },
]

export const JOB_TYPE_LIST = [
  {
    jobTypeCode: 'E',
    jobTypeName: 'Executive',
  },
  {
    jobTypeCode: 'N',
    jobTypeName: 'Non-Executive',
  },  
];

// Survey Mode
export const SURVEY_MODE = [
  {
    surveyModeCode: 'M',
    surveyModeName: 'Manual',
  },
  {
    surveyModeCode: 'O',
    surveyModeName: 'Online',
  },  
];

//Poll Category
export const POLL_CATEGORY = [
  {
    pollCatCode: 'ECO',
    pollCatName: 'Economic',
  },
  {
    pollCatCode: 'HR',
    pollCatName: 'HR',
  },
  {
    pollCatCode: 'LBR',
    pollCatName: 'Labour',
  },
];

//Poll Status
export const POLL_STATUS = [
  {
    statusID: 401,
    statusName: 'Draft',
  },
  {
    statusID: 410,
    statusName: 'Published',
  }, 
  {
    statusID: 404,
    statusName: 'Unpublished',
  },
];


// SNEF Admin Login
export const SNEF_ADMIN_LOGIN = 'SNEF Admin';

//To restrict the number of rows to be shown in the table
export const minNumOfJobRole = 1;
export const minNumOfCluster = 1;
export const minNumOfIndustry = 1;
export const minNumOfJobGrade = 1;
export const minNumOfEmploymentSize = 1;
export const minNumOfAnnualSalesTurnover = 1;
export const minNumOfYearsInCurrentCapacity = 1;
export const maxNumOfJobRoleWithoutReportTypeFilter= 5;

//To show industry in the cluster selected tgt with total MN , NMN, all industry
export const numClusterToShowAllRespIndustrry = 1;
//To show all cluster selected, total MN, NMN, all industry - benchmarking
export const maxNumOfClusterForBenchmarking = 5;
export const maxNumOfIndustryIfClusterSelected = 0;
//To show max of 5 industry - benchmarking
export const maxNumOfIndustryForBenchmarking = 5;
export const maxNumOfClusterToShowSpecificIndustryForBenchmarking = 0;
//To show only own industry, cluster, total MN, NMN, all industry - benchmarking
export const numToShowOwnIndustryForBenchmarking = 0;