import { GetterTree } from 'vuex';
import { StateInterface } from '../index';
import { AuthStateInterface } from './state';

const getters: GetterTree<AuthStateInterface, StateInterface> = {
  someAction(/* context */) {
    // your code
  },

  /*
  Description: Get the authentication status
  */
  isAuthenticated(): boolean {
    if (
      localStorage.getItem('AccessToken') != null &&
      localStorage.getItem('AccessToken') != ''
    ) {
      return true;
    }
    return false;
  },

  /*
  Description: Get user name
  */
  getExpiryTimestamp(state): string {
    return state.expiryTimestamp;
  },

  /*
  Description: Get user name
  */
  getUserName(state): string {
    return state.userName;
  },

  /*
  Description: Get user role ID
  */
  getUserRoleID(state): number {
    return state.userRoleID;
  },

  /*
  Description: Get user role
  */
  getUserRole(state): string {
    return state.userRole;
  },

  /*
  Description: Get the company name
  */
  getCompanyName(state): string {
    return state.companyName;
  },

  /*
  Description: Get agreement acceptance
  */
  getAgreementAcceptance(state): boolean {
    return state.agreementAcceptance;
  },

  /*
  Description: Get trial account
  */
  getTrialAccount(state): boolean {
    return state.trialAccount;
  },

  /*
  Description: Get user status ID
  */
  getUserStatusID(state): number {
    return state.userStatusID;
  },

  /*
  Description: Get user industry ID
  */
  getIndustryID(state): number {
    return state.industryID;
  },

  /*
  Description: Get user cluster code
  */
  getClusterCode(state): string {
    return state.clusterCode;
  },

  /*
  Description: Get user employment size ID
  */
  getEmploymentSizeID(state): number {
    return state.employmentSizeID;
  },

  /*
  Description: Get user annual sales turnover ID
  */
  getSalesTurnoverID(state): number {
    return state.salesTurnoverID;
  },


  /*
  Description: Get login provider flow name
  */
  getLoginProviderFlowName(state): string {
    return state.loginProviderFlowName;
  },

  /*
  Description: Get email
  */
  getEmail(state): string {
    return state.emailID;
  },
};

export default getters;
