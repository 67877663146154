import { boot } from 'quasar/wrappers';
import axios, { AxiosInstance } from 'axios';
import { Notify } from 'quasar';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
  }
}

let isAlreadyFetchingAccessToken: boolean = false;
let subscribers: any = [];

function onAccessTokenFetched(access_token: any) {
  subscribers = subscribers.filter((callback: any) => callback(access_token));
}

function addSubscriber(callback: any) {
  subscribers.push(callback);
}

const api = axios.create({
  baseURL: process.env.VUE_APP_EPUBLICATION_ENDPOINT_URL,
  headers: {
    'x-functions-key': process.env.VUE_APP_AZUREFUNCTION_HOST_KEY,
  },
});

export default boot(({ app, store, router }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API

  api.interceptors.request.use((config) => {
    const authData = localStorage.getItem('AccessToken') || '';
    if (authData == null || authData == '') {
      return config;
    }

    config.headers.common['Authorization'] = 'Bearer ' + authData;
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    /*
    Description: Handle error
    refer to https://www.learmoreseekmore.com/2020/12/vue3-jwt-auth-refreshtoken.html
    refer to https://forum.vuejs.org/t/vue-axios-refresh-token-and-retry-the-last-request-successfully-but-vue-cant-get-the-response-data-and-actually-api-has-returned/55261/5
    */
    async (error) => {
      if (error.response.status >= 400 && error.response.status <= 500) {
        const errorResponse = JSON.stringify(error.response.data);
        if (errorResponse.indexOf('TokenStatusID') > 0) {
          const tokenStatusID = parseInt(error.response.data.TokenStatusID);
          switch (tokenStatusID) {
            case -1:
              const { config } = error;
              const originalRequest = config;
              const refreshResponse = await api.post('/RefreshAccessToken', {
                refreshToken: localStorage.getItem('RefreshToken'),
              });
              type UserSessionType = {
                AccessToken: string;
                RefreshToken: string;
                AccessExpiryMinutes: number;
              };

              const userSession = <UserSessionType>(
                JSON.parse(JSON.stringify(refreshResponse.data))
              );

              if (!isAlreadyFetchingAccessToken) {
                isAlreadyFetchingAccessToken = true;
                store
                  .dispatch('authModule/updateAccessAndRefreshToken', {
                    idToken: userSession.AccessToken,
                    refreshToken: userSession.RefreshToken,
                    accessExpiryMinutes: userSession.AccessExpiryMinutes,
                  })
                  .then(() => {
                    isAlreadyFetchingAccessToken = false;
                    onAccessTokenFetched(userSession.AccessToken);
                  });
              }

              const retryOriginalRequest = new Promise((resolve) => {
                addSubscriber(() => {
                  originalRequest.headers.Authorization =
                    'Bearer ' + userSession.AccessToken;
                  resolve(axios(originalRequest));
                });
              });
              return retryOriginalRequest;
              break;
            case -3:
            case -4:
              Notify.create({
                color: 'red',
                icon: 'error',
                caption: error.response.data.ErrorDescription,
                message: 'Session Expired.',
                position: 'center',
                timeout: 5000,
                textColor: 'white',
                actions: [{ icon: 'close', color: 'white' }],
              });
              await store.dispatch('authModule/logoutFromPublication', null);
              router.push('/logout');
              return Promise.reject(error);
              break;
            default:
              break;
          }
        } else {
          Notify.create({
            color: 'red',
            icon: 'error',
            caption: JSON.stringify(error.response.data),
            message: 'Error in processing.',
            position: 'center',
            textColor: 'white',
            actions: [{ icon: 'close', color: 'white' }],
          });
          return Promise.reject(error);
        }
      }
    }
  );
});

export { axios, api };
