import { MutationTree } from 'vuex';
import { AuthStateInterface } from './state';
import { date } from 'quasar';

const mutation: MutationTree<AuthStateInterface> = {
  someMutation(/* state: ExampleStateInterface */) {
    // your code
  },

  /*
  Description: Requested login
  */
  auth_request(state: AuthStateInterface) {
    state.loginStatus = 'Login requested.';
  },

  /*
  Description: Error in login
  */
  auth_error(state: AuthStateInterface) {
    state.loginStatus = 'Error in logging in.';
    state.userName = '';
    state.userRole = '';
    state.userRoleID = -1;
    state.companyID = -1;
    state.companyName = '';
    state.expiryTimestamp = '';
    state.isAuthenticated = false;
    state.agreementAcceptance = false;
    state.trialAccount = false;
    state.userStatusID = -1;
    state.industryID = -1;
    state.clusterCode = '';
    state.employmentSizeID = -1;
    state.salesTurnoverID = -1;
    state.loginProviderFlowName = '';
    state.emailID = '';
  },

  /*
  Description: Success login, update the state to keep the user data
  */
  auth_success(
    state: AuthStateInterface,
    userSession: {
      userName: string;
      userRole: string;
      userRoleID: number;
      companyID: number;
      companyName: string;
      accessExpiryMinutes: number;
      agreementAcceptance: boolean;
      trialAccount: boolean;
      userStatusID: number;
      industryID: number;
      clusterCode: string;
      employmentSizeID: number;
      salesTurnoverID: number;
      loginProviderFlowName: string;
      emailID: string;
    }
  ) {
    const expiryDate = date.addToDate(new Date(), { minutes: userSession.accessExpiryMinutes});
    state.expiryTimestamp = expiryDate.toISOString();
    state.loginStatus = 'Success login.';
    state.userName = userSession.userName;
    state.userRole = userSession.userRole;
    state.userRoleID = userSession.userRoleID;
    state.companyID = userSession.companyID;
    state.companyName = userSession.companyName;
    state.isAuthenticated = true;
    state.agreementAcceptance = userSession.agreementAcceptance;
    state.trialAccount = userSession.trialAccount;
    state.userStatusID = userSession.userStatusID;
    state.industryID = userSession.industryID;
    state.clusterCode = userSession.clusterCode;
    state.employmentSizeID = userSession.employmentSizeID;
    state.salesTurnoverID = userSession.salesTurnoverID;
    state.loginProviderFlowName = userSession.loginProviderFlowName;
    state.emailID = userSession.emailID;
  },

  /*
  Description: Logout, clear the state data
  */
  logout(state: AuthStateInterface) {
    state.loginStatus = '';
    state.userName = '';
    state.userRole = '';
    state.userRoleID = -1;
    state.companyID = -1;
    state.companyName = '';
    state.expiryTimestamp = '';
    state.isAuthenticated = false;
    state.agreementAcceptance = false;
    state.trialAccount = false;
    state.userStatusID = -1;
    state.industryID = -1;
    state.clusterCode = '';
    state.employmentSizeID = -1;
    state.salesTurnoverID = -1;
    state.loginProviderFlowName = '';
    state.emailID = '';
  },

  /*
  Description: Refresh token
  */
  auth_refresh(state: AuthStateInterface,  accessExpiryMinutes: number) {
    state.loginStatus = 'Token updated';
    const expiryDate = date.addToDate(new Date(), { minutes: accessExpiryMinutes});
    state.expiryTimestamp = expiryDate.toISOString();
  },


  agreement_acceptance(state: AuthStateInterface) {
    state.agreementAcceptance = true;
  },
};

export default mutation;
