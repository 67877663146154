export interface AuthStateInterface {
  prop: boolean;
  loginStatus: string;
  userName: string;
  userRole: string;
  userRoleID: number;
  companyID: number;
  companyName: string;
  isAuthenticated: boolean;
  expiryTimestamp: string;
  agreementAcceptance: boolean;
  trialAccount: boolean;
  userStatusID: number;
  industryID: number;
  clusterCode: string;
  employmentSizeID: number;
  salesTurnoverID: number;
  loginProviderFlowName: string;
  emailID: string;
}

function state(): AuthStateInterface {
  return {
    prop: false,
    loginStatus: '',
    userName: '',
    userRole: '',
    userRoleID: -1,
    companyID: -1,
    companyName: '',
    isAuthenticated: false,
    expiryTimestamp: (new Date()).toISOString(),
    agreementAcceptance: false,
    trialAccount: false,
    userStatusID: -1,
    industryID: -1,
    clusterCode: '',
    employmentSizeID: -1,
    salesTurnoverID: -1,
    loginProviderFlowName: '',
    emailID: ''
  };
}

export default state;
