import { ActionTree } from 'vuex';
import { StateInterface } from '../index';
import { AuthStateInterface } from './state';
import { api } from 'boot/axios';

const actions: ActionTree<AuthStateInterface, StateInterface> = {
  /*
  Description: Login to ePublication and get access token
  */
  async loginToPublication({ commit }, idToken: string) {
    return await new Promise((resolve, reject) => {
      commit('auth_request');
      api
        .post('/LoginToPublication', {
          strIdToken: idToken,
        })
        .then((response) => {
          type UserSessionType = {
            AccessToken: string;
            RefreshToken: string;
            PersonName: string;
            PersonRole: string;
            PersonRoleID: number;
            CompanyID: number;
            CompanyName: string;
            AccessExpiryMinutes: number;
            AgreementAcceptance: boolean;
            TrialAccount: boolean;
            UserStatusID: number;
            IndustryID: number;
            ClusterCode: string;
            EmploymentSizeID: number;
            SalesTurnoverID: number;
            EmailID: string;
          };

          const userSession = <UserSessionType>(
            JSON.parse(JSON.stringify(response.data))
          );
          // Use localstorage
          localStorage.setItem('AccessToken', userSession.AccessToken);
          localStorage.setItem('RefreshToken', userSession.RefreshToken);

          commit('auth_success', {
            userAccessToken: userSession.AccessToken,
            userName: userSession.PersonName,
            userRole: userSession.PersonRole,
            userRoleID: userSession.PersonRoleID,
            companyID: userSession.CompanyID,
            companyName: userSession.CompanyName,
            accessExpiryMinutes: userSession.AccessExpiryMinutes,
            agreementAcceptance: userSession.AgreementAcceptance,
            trialAccount: userSession.TrialAccount,
            userStatusID: userSession.UserStatusID,
            industryID: userSession.IndustryID,
            clusterCode: userSession.ClusterCode,
            employmentSizeID: userSession.EmploymentSizeID,
            salesTurnoverID: userSession.SalesTurnoverID,
            emailID: userSession.EmailID
          });

          resolve(response);
        })
        .catch((err) => {
          commit('auth_error');
          localStorage.removeItem('AccessToken');
          localStorage.removeItem('RefreshToken');
          reject(err);
        });
    });
  },


  /*
  Description: Login using Singpass
  */
  async loginToPublicationWithSingpass({ commit }, authCode: string) {
    return await new Promise((resolve, reject) => {
      commit('auth_request');
      api
        .post('/LoginWithSingpass', {
          authCode: authCode,
        })
        .then((response) => {
          type UserSessionType = {
            AccessToken: string;
            RefreshToken: string;
            PersonName: string;
            PersonRole: string;
            PersonRoleID: number;
            CompanyID: number;
            CompanyName: string;
            AccessExpiryMinutes: number;
            AgreementAcceptance: boolean;
            TrialAccount: boolean;
            UserStatusID: number;
            IndustryID: number;
            ClusterCode: string;
            EmploymentSizeID: number;
            SalesTurnoverID: number;
            LoginProviderFlowName: string;
            EmailID: string;
          };

          const userSession = <UserSessionType>(
            JSON.parse(JSON.stringify(response.data))
          );
          // Use localstorage
          localStorage.setItem('AccessToken', userSession.AccessToken);
          localStorage.setItem('RefreshToken', userSession.RefreshToken);

          commit('auth_success', {
            userAccessToken: userSession.AccessToken,
            userName: userSession.PersonName,
            userRole: userSession.PersonRole,
            userRoleID: userSession.PersonRoleID,
            companyID: userSession.CompanyID,
            companyName: userSession.CompanyName,
            accessExpiryMinutes: userSession.AccessExpiryMinutes,
            agreementAcceptance: userSession.AgreementAcceptance,
            trialAccount: userSession.TrialAccount,
            userStatusID: userSession.UserStatusID,
            industryID: userSession.IndustryID,
            clusterCode: userSession.ClusterCode,
            employmentSizeID: userSession.EmploymentSizeID,
            salesTurnoverID: userSession.SalesTurnoverID,
            loginProviderFlowName: userSession.LoginProviderFlowName,
            emailID: userSession.EmailID,
          });

          resolve(response);
        })
        .catch((err) => {
          commit('auth_error');
          localStorage.removeItem('AccessToken');
          localStorage.removeItem('RefreshToken');
          reject(err);
        });
    });
  },


  /*
  Description: Login to system with Staff AD and get access token
  */
  async LoginWithStaffAD({ commit }, userInfo: { strEmail: string; strPersonName: string }) {
    return await new Promise((resolve, reject) => {
      commit('auth_request');
      api
        .post('/SetLoginWithAD', {
          strEmail: userInfo.strEmail,
          strPersonName: userInfo.strPersonName,
        })
        .then((response) => {
          type UserSessionType = {
            AccessToken: string;
            RefreshToken: string;
            PersonName: string;
            PersonRole: string;
            PersonRoleID: number;
            CompanyID: number;
            CompanyName: string;
            AccessExpiryMinutes: number;
            AgreementAcceptance: boolean;
            TrialAccount: boolean;
            UserStatusID: number;
            IndustryID: number;
            ClusterCode: string;
            EmploymentSizeID: number;
            SalesTurnoverID: number;
            EmailID: string;
          };

          const userSession = <UserSessionType>(
            JSON.parse(JSON.stringify(response.data))
          );
          // Use localstorage
          localStorage.setItem('AccessToken', userSession.AccessToken);
          localStorage.setItem('RefreshToken', userSession.RefreshToken);

          commit('auth_success', {
            userAccessToken: userSession.AccessToken,
            userName: userSession.PersonName,
            userRole: userSession.PersonRole,
            userRoleID: userSession.PersonRoleID,
            companyID: userSession.CompanyID,
            companyName: userSession.CompanyName,
            accessExpiryMinutes: userSession.AccessExpiryMinutes,
            agreementAcceptance: userSession.AgreementAcceptance,
            trialAccount: userSession.TrialAccount,
            userStatusID: userSession.UserStatusID,
            industryID: userSession.IndustryID,
            clusterCode: userSession.ClusterCode,
            employmentSizeID: userSession.EmploymentSizeID,
            salesTurnoverID: userSession.SalesTurnoverID,
            emailID: userSession.EmailID
          });

          resolve(response);
        })
        .catch((err) => {
          commit('auth_error');
          localStorage.removeItem('AccessToken');
          localStorage.removeItem('RefreshToken');
          reject(err);
        });
    });
  },


  /*
  Description: Logout from ePublication, clear local storage and header
  */
  async logoutFromPublication({ commit }) {
    return await new Promise((resolve) => {
      api.post('/LogoutFromPublication', null).then(() => {
        commit('logout');
        localStorage.removeItem('AccessToken');
        localStorage.removeItem('RefreshToken');
        delete api.defaults.headers.common['Authorization'];
        resolve(null);
      });
    });
  },

  /*
  Description: Update token
  */
  async updateAccessAndRefreshToken(
    { commit },
    payload: {
      idToken: string;
      refreshToken: string;
      accessExpiryMinutes: number;
    }
  ) {
    return await new Promise((resolve) => {
      // Use localstorage
      commit('auth_refresh', payload.accessExpiryMinutes);
      localStorage.setItem('AccessToken', payload.idToken);
      localStorage.setItem('RefreshToken', payload.refreshToken);
      resolve(null);
    });
  },

  /*
  Description: Accept terms & conditions
  */
  async updateAgreementAcceptance({ commit }) {
    return await new Promise((resolve) => {
      api.post('/SetAgreementAcceptance', null).then(() => {
        commit('agreement_acceptance');
        resolve(null);
      });
    });
  },
};

export default actions;
