export const ANNUAL_LEAVE_ENTITLEMENTS_BY_INDUSTRY = [
  {
    headerColumn: true,
    headerSequence: ['Minimum and Maximum Number of Days of Annual Leave Entitlement', 'Minimum (Days)', 'Maximum (Days)'],
    colSpan: [3, 4, 4],
    colSpanSingleIndustry: [2, 4, 4],
    haveQuantile: true
  },
  {
    name: 'Cluster',
    align: 'left',
    label: 'Cluster',
    sortable: true,
    field: 'ClusterName',
  },
  {
    name: 'Industry',
    align: 'left',
    label: 'Industry',
    sortable: true,
    field: 'IndustryName',
  },

  {
    name: 'Level of Employee',
    align: 'left',
    label: 'Level of Employee',
    sortable: true,
    field: 'EmployeeLevelName',
  },

  {
    name: 'Minimum Number of Days (N)',
    align: 'center',
    label: '(N)',
    field: 'MinimumNumber',
  },
  {
    name: 'Minimum Number of Days (Lower Quartile)',
    align: 'center',
    label: 'Lower Quartile',
    field: 'MinimumLQ',
  },
  {
    name: 'Minimum Number of Days (Median) (Days)',
    align: 'center',
    label: 'Median',
    field: 'MinimumMD',
  },
  {
    name: 'Minimum Number of Days (Upper Quartile)',
    align: 'center',
    label: 'Upper Quartile',
    field: 'MinimumUQ',
    headerName: '',
    colSpan: 0
  },
  {
    name: 'Maximum Number of Days (N)',
    align: 'center',
    label: '(N)',
    field: 'MaximumNumber',
  },
  {
    name: 'Maximum Number of Days (Lower Quartile)',
    align: 'center',
    label: 'Lower Quartile',
    field: 'MaximumLQ',
  },
  {
    name: 'Maximum Number of Days (Median) (Days)',
    align: 'center',
    label: 'Median',
    field: 'MaximumMD',

  },
  {
    name: 'Maximum Number of Days (Upper Quartile)',
    align: 'center',
    label: 'Upper Quartile',
    field: 'MaximumUQ',
  },
];

export const ANNUAL_LEAVE_ENTITLEMENTS_BY_EMPSIZE = [
  {
    headerColumn: true,
    headerSequence: ['Minimum and Maximum Number of Days of Annual Leave Entitlement (Days)', 'Minimum (Days)', 'Maximum (Days)'],
    colSpan: [2, 4, 4],
    colSpanSingleIndustry: [2, 4, 4],
    haveQuantile: true
  },

  {
    name: 'Employment Size',
    align: 'left',
    label: 'Employment Size',
    sortable: true,
    field: 'EmploymentSizeName',
  },
  {
    name: 'Level of Employee',
    align: 'left',
    label: 'Level of Employee',
    sortable: true,
    field: 'EmployeeLevelName',
  },
  {
    name: 'Minimum Number of Days (N)',
    align: 'center',
    label: '(N)',
    field: 'MinimumNumber',
  },
  {
    name: 'Minimum Number of Days (Lower Quartile)',
    align: 'center',
    label: 'Lower Quartile',
    field: 'MinimumLQ',
  },
  {
    name: 'Minimum Number of Days (Median)',
    align: 'center',
    label: 'Median',
    field: 'MinimumMD',
  },
  {
    name: 'Minimum Number of Days (Upper Quartile)',
    align: 'center',
    label: 'Upper Quartile',
    field: 'MinimumUQ',
  },
  {
    name: 'Maximum Number of Days (N)',
    align: 'center',
    label: '(N)',
    field: 'MaximumNumber',
  },
  {
    name: 'Maximum Number of Days (Lower Quartile)',
    align: 'center',
    label: 'Lower Quartile',
    field: 'MaximumLQ',
  },
  {
    name: 'Maximum Number of Days (Median)',
    align: 'center',
    label: 'Median',
    field: 'MaximumMD',
  },
  {
    name: 'Maximum Number of Days (Upper Quartile)',
    align: 'center',
    label: 'Upper Quartile',
    field: 'MaximumUQ',
  },
];

export const BY_INDUSTRY = [
  {
    headerColumn: true,
    headerSequence: [],
    colSpan: [],
    colSpanSingleIndustry: [],
    haveQuantile: false
  },
  {
    name: 'Cluster',
    align: 'left',
    label: 'Cluster',
    sortable: true,
    field: 'ClusterName',
  },
  {
    name: 'Industry',
    align: 'left',
    label: 'Industry',
    sortable: true,
    field: 'IndustryName',
  },

  {
    name: 'Level of Employee',
    align: 'left',
    label: 'Level of Employee',
    sortable: true,
    field: 'EmployeeLevelName',
  },
  {
    name: '(N)',
    align: 'center',
    label: '(N)',
    sortable: true,
    field: 'Total',
  },
]

export const BY_EMPSIZE = [
  {
    headerColumn: true,
    headerSequence: [],
    colSpan: [],
    colSpanSingleIndustry: [],
    haveQuantile: false
  },

  {
    name: 'Employment Size',
    align: 'left',
    label: 'Employment Size',
    sortable: true,
    field: 'EmploymentSizeName',
  }, {
    name: 'Level of Employee',
    align: 'left',
    label: 'Level of Employee',
    sortable: true,
    field: 'EmployeeLevelName',
  }, {
    name: '(N)',
    align: 'center',
    label: '(N)',
    sortable: true,
    field: 'Total',
  }]


  
export const BY_INDUSTRY_METHOD_OF_PROVISION = [
  {
    headerColumn: true,
    headerSequence: [],
    colSpan: [],
    colSpanSingleIndustry: [],
    haveQuantile: false
  },
  {
    name: 'Cluster',
    align: 'left',
    label: 'Cluster',
    sortable: true,
    field: 'ClusterName',
  },
  {
    name: 'Industry',
    align: 'left',
    label: 'Industry',
    sortable: true,
    field: 'IndustryName',
  },

  {
    name: 'Level of Employee',
    align: 'left',
    label: 'Level of Employee',
    sortable: true,
    field: 'EmployeeLevelName',
  },
  {
    name: '(N)',
    align: 'center',
    label: '(N)',
    sortable: true,
    field: 'Total',
  },
]

export const BY_EMPSIZE_METHOD_OF_PROVISION  = [
  {
    headerColumn: true,
    headerSequence: [],
    colSpan: [],
    colSpanSingleIndustry: [],
    haveQuantile: false
  },

  {
    name: 'Employment Size',
    align: 'left',
    label: 'Employment Size',
    sortable: true,
    field: 'EmploymentSizeName',
  }, {
    name: 'Level of Employee',
    align: 'left',
    label: 'Level of Employee',
    sortable: true,
    field: 'EmployeeLevelName',
  }, {
    name: '(N)',
    align: 'center',
    label: '(N)',
    sortable: true,
    field: 'Total',
  }]


export const BY_INDUSTRY_COMPANY_WIDE = [
  {
    headerColumn: true,
    headerSequence: [],
    colSpan: [],
    colSpanSingleIndustry: [],
    haveQuantile: false
  },
  {
    name: 'Cluster',
    align: 'left',
    label: 'Cluster',
    sortable: true,
    field: 'ClusterName',
  },
  {
    name: 'Industry',
    align: 'left',
    label: 'Industry',
    sortable: true,
    field: 'IndustryName',
  },
  {
    name: '(N)',
    align: 'center',
    label: '(N)',
    sortable: true,
    field: 'Total',
  }

]

export const BY_EMPSIZE_COMPANY_WIDE = [
  {
    headerColumn: true,
    headerSequence: [],
    colSpan: [],
    colSpanSingleIndustry: [],
    haveQuantile: false
  },

  {
    name: 'Employment Size',
    align: 'left',
    label: 'Employment Size',
    sortable: true,
    field: 'EmploymentSizeName',
  }, {
    name: '(N)',
    align: 'center',
    label: '(N)',
    sortable: true,
    field: 'Total',
  }]

export const BY_INDUSTRY_QUANTILE = [
  {
    headerColumn: true,
    headerSequence: [],
    colSpan: [],
    colSpanSingleIndustry: [],
    haveQuantile: true

  },
  {
    name: 'Cluster',
    align: 'left',
    label: 'Cluster',
    sortable: true,
    field: 'ClusterName',
  },
  {
    name: 'Industry',
    align: 'left',
    label: 'Industry',
    sortable: true,
    field: 'IndustryName',
  },
  {
    name: 'Level of Employee',
    align: 'left',
    label: 'Level of Employee',
    sortable: true,
    field: 'EmployeeLevelName',
  },
  {
    name: '(N)',
    align: 'center',
    label: '(N)',
    field: 'Number',
  },
  {
    name: 'Lower Quartile',
    align: 'center',
    label: 'Lower Quartile',
    field: 'LowerQuartile',
  },
  {
    name: 'Median',
    align: 'center',
    label: 'Median',
    field: 'Median',
  },
  {
    name: 'Upper Quartile',
    align: 'center',
    label: 'Upper Quartile',
    field: 'UpperQuartile',
  }
];

export const BY_EMPSIZE_QUANTILE = [
  {
    headerColumn: true,
    headerSequence: [],
    colSpan: [],
    colSpanSingleIndustry: [],
    haveQuantile: true
  },

  {
    name: 'Employment Size',
    align: 'left',
    label: 'Employment Size',
    sortable: true,
    field: 'EmploymentSizeName',
  },  {
    name: 'Level of Employee',
    align: 'left',
    label: 'Level of Employee',
    sortable: true,
    field: 'EmployeeLevelName',
  },
  {
    name: '(N)',
    align: 'center',
    label: '(N)',
    field: 'Number',
  },
  {
    name: 'Lower Quartile',
    align: 'center',
    label: 'Lower Quartile',
    field: 'LowerQuartile',
  },
  {
    name: 'Median',
    align: 'center',
    label: 'Median',
    field: 'Median',
  },
  {
    name: 'Upper Quartile',
    align: 'center',
    label: 'Upper Quartile',
    field: 'UpperQuartile',
  }
]
