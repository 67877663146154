import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/Login.vue') },
      {
        path: '/dashboard',
        component: () => import('src/pages/user/UserDashboard.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/help-and-support',
        component: () => import('src/pages/Faq.vue'),
      },
      {
        path: '/profile',
        component: () => import('src/pages/user/OrganisationProfile.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/public-data',
        component: () => import('src/pages/user/PublicData.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/insights',
        component: () => import('src/pages/user/Insights.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/quick-poll',
        component: () => import('src/pages/user/QuickPoll.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/admin-dashboard',
        component: () => import('src/pages/admin/AdminDashboard.vue'),
        meta: { requiresAuth: true, isAdmin: true },
      },
      {
        path: '/account-management',
        component: () => import('src/pages/admin/AccountManagement.vue'),
        meta: { requiresAuth: true, isAdmin: true },
      },
      {
        path: '/staff-management',
        component: () => import('pages/admin/StaffManagement.vue'),
        meta: { requiresAuth: true, isAdmin: true },
      },
      {
        path: '/insights-management',
        component: () => import('src/pages/admin/InsightsManagement.vue'),
        meta: { requiresAuth: true, isAdmin: true },
      },
      {
        path: '/survey-management',
        component: () => import('src/pages/admin/SurveyManagement.vue'),
        meta: { requiresAuth: true, isAdmin: true },
      },
      {
        path: '/data-management',
        component: () => import('src/pages/admin/DataManagement.vue'),
        meta: { requiresAuth: true, isAdmin: true },
      },
      {
        path: '/quick-poll-management',
        component: () => import('src/pages/admin/QuickPollManagement.vue'),
        meta: { requiresAuth: true, isAdmin: true },
      },
      {
        path: '/home',
        component: () => import('src/pages/user/Home.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/preview-report',
        component: () => import('src/pages/user/PreviewReport.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/contact-us',
        component: () => import('src/pages/ContactUs.vue'),
      },
      { path: '/terms-of-use', component: () => import('pages/TermsOfUse.vue') },
      { path: '/logout', component: () => import('pages/Logout.vue') },
      {
        path: '/no-account',
        component: () => import('pages/NoAccount.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/no-account-sp',
        component: () => import('pages/NoAccountSingpass.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/account-verification',
        component: () => import('pages/AccountVerification.vue'),

      },
      {
        path: '/report',
        component: () => import('src/pages/admin/Report.vue'),
        meta: { requiresAuth: true, isAdmin: true },
      },
      {
        path: '/weosurvey2022',
        component: () => import('src/pages/survey/WEOSurvey2022.vue'),
      },
      {
        path: '/weosurvey2023',
        component: () => import('src/pages/survey/WEOSurvey2023.vue'),
      },
      {
        path: '/weosurvey2024',
        component: () => import('src/pages/survey/WEOSurvey2024.vue'),
      },
      { path: '/salary-report-and-benchmark', component: () => import('pages/SalaryGuide.vue') },
      { path: '/employee-benefits-report', component: () => import('pages/BenefitsGuide.vue') },

    ],
  },
  { path: '/callback', component: () => import('layouts/MainLayout.vue') },
  { path: '/spcallback', component: () => import('layouts/MainLayout.vue') },
  { path: '/adcallback', component: () => import('layouts/MainLayout.vue') },

  {
    path: '/snefstaff',
    component: () => import('layouts/MainLayout.vue'),
    children: [{ path: '', component: () => import('pages/Login.vue') }],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue'),
  },
];

export default routes;
